import moment from "moment";
import { billingDatePickerOptions } from "../utils/billingDatePickerOptions";

//! @ngInject
export function billingInvoicesCtrl($scope, $rootScope, NgTableParams, $state, itemSearchPageManager, selectionLogic, toaster, billingInvoicesProcessor, DatabaseApi, $filter, FilterUtils, SweetAlert) {    $scope.caregivers = DatabaseApi.caregivers();
    $scope.patients = DatabaseApi.patients();
    $rootScope.$on("got_caregivers_data", function (event) {
        $scope.caregivers = DatabaseApi.caregivers();
    });

    $rootScope.$on("got_patients_data", function (event) {
        $scope.patients = DatabaseApi.patients();
    });
    $scope.tableParams = null;
    $scope.openPatientModal = $rootScope.openPatientModal;
    $scope.globalFilter = { val: '' };

    $scope.eBillingProviderOptions = DatabaseApi.eBillingProviders().map(item => ({
        id: item, label: item
    }));
    $scope.dateRangeOptions = billingDatePickerOptions;

    $scope.selectedEBillingProviders = [];

    function initialize() {
        initPageManager();
        initSelection([]);
        $scope.pageManager.resetSearchParamsByUrl();

        // Load filters
        const storageFilters = FilterUtils.rootFilters.billingInvoicesCtrl;
        if (storageFilters !== undefined) {
            $scope.pageManager.setSearchParams(storageFilters);
            $scope.selectedDates = { startDate: storageFilters.from.value, endDate: storageFilters.to.value };
        } else {
            $scope.selectedDates = { startDate: moment().subtract(7, "days"), endDate: moment() };
        }
        $scope.selectedEntity = {
            type: '',
            id: ''
        };
    }

    $scope.$on("$destroy", function () {
        const filters = $scope.pageManager.searchParams;
        FilterUtils.rootFilters.billingInvoicesCtrl = angular.copy(filters);
    });

    const initPageManager = function () {
        $scope.pageManager = itemSearchPageManager.createSearchPageManager("/invoices");

        $scope.officeDataManager = $scope.pageManager.getOfficeDataManager();
        $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();

        // var convertStatusValueToString = function (statusValue) {
        //     return statusValue;
        // };

        // var convertStatusValueFromString = function (valueString) {
        //     return valueString;
        // };

        $scope.pageManager.initFromToDateParams();
        $scope.pageManager.initSearchParam("invoiceId", "", {
            isEntity: true,
        });
        $scope.pageManager.initSearchParam("exportBatchId", "", {
            isEntity: true,
        });
        $scope.pageManager.initSearchParam("selectedPatientId", null);
        $scope.pageManager.initContractTypesParam();
        $scope.pageManager.initOfficeParam();
        $scope.pageManager.initSearchParamDeprecated("exportStatus", "", "exported", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("paymentStatus", "", "payment", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("upToDate", "true", "up-to-date", undefined, undefined, true);
        $scope.pageManager.initSearchParamDeprecated("billType", "false", "pending", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("manualHold", "", "hold", undefined, undefined, "");
        $scope.pageManager.initSearchParamDeprecated("eBillingProvider", "", "e-billing-provider", undefined, undefined, "");
        $scope.pageManager.updateSearchParamValue("upToDate", true);
        $scope.pageManager.initSearchParam(
            "invoiceBatchId",
            $state.params.batchId ? $state.params.batchId : "",
            { isEntity: true }
        );
        $scope.pageManager.initSearchParam("dateByInvoice", true, {
            placeholderValue: true,
            queryName: "date-by-invoice",
        });
    };

    const initSelection = function (items) {
        $scope.selectionLogic = selectionLogic.createNewLogic(function (item) {
            if (!item.exported) $scope.selectionLogic.addItemToCollection(item);
        });
        if (items) {
            items.forEach((item) => {
                $scope.selectionLogic.initItem(item);
            });
        }
    };

    const mapItems = function (items) {
        items.forEach(function (item) {
            if (!item.contractTypeName && item.contractTypeId) {
                const contractType = $scope.contractTypesDataManager.getContractTypeById(item.contractTypeId);
                item.contractTypeName = (contractType && contractType.name) || item.contractTypeId;
            }

            //should receive the 'exported' indication from the server
            item.exported = Boolean(item.exportBatchId);

            $scope.selectionLogic.initItem(item);
        });
    };

    const initTable = function (items) {
        initSelection(items);
        items.forEach(function (item) {
            if (item.caregiverId) {
                item.caregiver = $scope.caregivers[item.caregiverId];
            }
            if (item.patientId) {
                item.patient = $scope.patients[item.patientId];
            }
        });
        const hasItems = items && items.length;
        if (hasItems) {
            mapItems(items);
            // toaster.pop("success", items.length + " Invoices were loaded successfully");
        }

        const options = {
            count: 25
        };
        $scope.tableParams = new NgTableParams(options, {
            dataset: items
        });

        $scope.summedTotalVisits = items.reduce(function (a, b) {
            return a + b.visitCount;
        }, 0);
    };

    $scope.loadItems = function () {
        $scope.globalFilter.val = '';
        $scope.pageManager.searchParams.from.value = $scope.selectedDates.startDate;
        $scope.pageManager.searchParams.to.value = $scope.selectedDates.endDate;
        $scope.pageManager.searchParams.eBillingProvider.value = $scope.selectedEBillingProviders.map(eBillingProvider => eBillingProvider.id);
        
        $scope.pageManager.executeSearch().then(function (response) {
            $scope.allInvoices = [...response.data.invoices];
            initTable(response.data.invoices);
        }, function (error) {
            toaster.pop("error", "Failed to load invoices");
        });
    };

    $scope.clickTableRow = function (item) {
        if (!item) return;
        $rootScope.openInvoiceModal({ invoiceId: item.id });
    };

    $scope.openModalToExportInvoices = function () {
        const invoiceIds = [];
        $scope.selectionLogic.getSelectedItems().forEach(function (invoice) {
            invoiceIds.push(invoice.id);
        });

        billingInvoicesProcessor.openInvoicesExporterModalByInvoices(invoiceIds, $scope.loadItems);
    };

    $scope.openModalToForceExportInvoices = function () {
        const invoiceIds = [];
        $scope.selectionLogic.getSelectedItems().forEach(function (invoice) {
            invoiceIds.push(invoice.id);
        });

        billingInvoicesProcessor.openInvoicesForceExporterModalByInvoices(invoiceIds, $scope.loadItems);
    };

    $scope.openModalToRecheckPendingInvoices = function () {
        const invoiceIds = [];
        $scope.selectionLogic.getSelectedItems().forEach(function (invoice) {
            invoiceIds.push(invoice.id);
        });

        billingInvoicesProcessor.openRecheckPendingInvoicesModalByInvoices(invoiceIds, $scope.loadItems);
    };

    $scope.openModalToDeleteInvoices = function () {
        const invoiceIds = [];
        $scope.selectionLogic.getSelectedItems().forEach(function (invoice) {
            invoiceIds.push(invoice.id);
        });

        SweetAlert.swal({
            title: "Delete invoices",
            text: "Are you sure you want to delete the invoices for the " + invoiceIds.length + " selected invoices? ",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3077EB",
            confirmButtonText: "Yes, remove",
            closeOnConfirm: true,
            closeOnCancel: true
        }, function (isConfirm) {
            if (isConfirm) {
                DatabaseApi.post('agencies/' + $rootScope.agencyId +
                    '/agency_members/' + $rootScope.agencyMemberId + '/delete_invoices/', { invoiceIds }).then(function (res) {
                        toaster.pop('success', "Success", "Deleted invoices successfully");
                        $scope.loadItems();
                    }, function (err) {
                        toaster.pop('error', "Something went wrong", "Failed to delete invoices");
                    })
            }
        });
    }

    initialize();

    $scope.applyGlobalSearch = function (term) {
        const filter = { $: term };
        if ($scope.tableParams) {
            angular.extend($scope.tableParams.filter(), filter);
            const allInvoices = $filter('filter')($scope.allInvoices, $scope.globalFilter.val);
            initSelection(allInvoices)
        }
    };

    $scope.exportTable = function () {
        $scope.loadingCSV = true;
        const rows = [];
        const titles = [
            'Exports',
            'Payment',
            'ID',
            'Total Billed Hours',
            'Total Billed',
            'Total Paid',
            'Total Units',
            'Patient',
            'Contract',
            'Invoice Batch ID',
            'Number of Visits',
            'Created at',
            'Patient Medflyt ID',
            'Patient HHA Exchange ID',
            'Patient Admission ID',
            'Patient SSN',
            'Patient Medicare Number',
            'Patient Medicaid Number',
            'Patient Address',
            'Patient Gender'
        ];
        rows.push(titles);

        // Filter table data by global filter
        const allInvoices = $filter('filter')($scope.allInvoices, $scope.globalFilter.val);

        allInvoices.forEach(dataRow => {
            const csvRow = [];
            titles.forEach(title => {
                let toPush = "";
                switch (title) {
                    case "Exports":
                        toPush = dataRow.exportedStatus || '';
                        break;
                    case "Payment":
                        toPush = dataRow.paymentStatus || '';
                        break;
                    case "ID":
                        toPush = dataRow.displayId.replace('#', '') || '';
                        break;
                    case "Total Billed Hours":
                        toPush = $filter("secondsToLabel")(dataRow.totalBilledSeconds);
                        break;
                    case "Total Billed":
                        toPush = $filter("centsToDollars")(dataRow.totalBilled, true);
                        break;
                    case "Total Paid":
                        toPush = $filter("centsToDollars")(dataRow.totalPaid, true);
                        break;
                    case "Total Units":
                        toPush = dataRow.totalUnitsThousandths / 1000;
                        break;
                    case "Patient":
                        toPush = dataRow.patientName || '';
                        break;
                    case "Contract":
                        toPush = dataRow.contractTypeName || '';
                        break;
                    case "Invoice Batch ID":
                        toPush = dataRow.invoiceBatchDisplayId.replace('#', '') || '';
                        break;
                    case "Number of Visits":
                        toPush = dataRow.visitCount || '';
                        break;
                    case "Created at":
                        toPush = $filter("mfShortTime")(dataRow.createdAt, ['withDate']) || '';
                        break;
                    case "Patient Medflyt ID":
                        toPush = dataRow.patientId || '';
                        break;
                    case "Patient HHA Exchange ID":
                        toPush = dataRow.patientExternalId || '';
                        break;
                    case "Patient Admission ID":
                        toPush = dataRow.patientAdmissionId || '';
                        break;
                    case "Patient SSN":
                        toPush = dataRow.patientSSN || '';
                        break;
                    case "Patient Medicare Number":
                        toPush = dataRow.patientMedicareNumber || '';
                        break;
                    case "Patient Medicaid Number":
                        toPush = dataRow.patientMedicaidNumber || '';
                        break;
                    case "Patient Address":
                        toPush = dataRow.patientAddress ? dataRow.patientAddress.replace('#', '') : '';
                        break;
                    case "Patient Gender":
                        toPush = dataRow.patientGender || '';
                        break;
                    default:
                        break;
                }

                csvRow.push("\"" + toPush + "\"");
            });
            rows.push(csvRow);
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", getExportedFileName());
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };

    $scope.handleEntitySelection = (type, id) => {
        $scope.selectedEntity.type = type;
        $scope.selectedEntity.id = id;
        $scope.pageManager.updateSearchParamValue("selectedPatientId", id);
    }

    $scope.handleEntityDeselect = () => {
        $scope.selectedEntity.id = null;
        $scope.selectedEntity.type = null;
        $scope.pageManager.updateSearchParamValue("selectedPatientId", null);
    }

    const getExportedFileName = function () {
        const filename = "medflyt-invoices"
            + $filter("date")(new Date($scope.pageManager.searchParams.from.value), "yyyy-MM-dd")
            + "-to-"
            + $filter("date")(new Date($scope.pageManager.searchParams.to.value), "yyyy-MM-dd")
            + ".csv";
        return filename;
    }
};
