import moment from "moment";

export const billingDatePickerOptions = {
    ranges: {
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 14 Days": [moment().subtract(13, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "Last 90 Days": [moment().subtract(89, "days"), moment()],
        "Last 180 Days": [moment().subtract(179, "days"), moment()],
        "Last 365 Days": [moment().subtract(364, "days"), moment()],
        "Until Now": [
            moment('2000-01-01'),
            moment()
        ]
    },
    alwaysShowCalendars: true,
    applyClass: "btn-primary",
    locale: {
        direction: "ltr date-range-picker-v2",
        format: "D MMM YY"
    },
    opens: "right",
    autoApply: true,
    minDate: new Date("2000-01-01"),
};